import { Country } from "react-phone-number-input";

export const langues = [
    "Allemand","Américain","Anglais","Arabe"
    ,"Croate – Serbe","Espagnol","Italien", "Néerlandais"
    ,"Polonais","Portugais","Roumain","Russe","Suédois", "Persan", "Pachtou", "Dari"
];

export const specialites = [
    "Architecture","Bâtiment","Comptabilité",
    "Copropriété","Diagnostic d'entreprise",
    "Estimation immobilière","Gestion d'entreprise",
    "Informatique"
];


export const phonesNumberCountries: Country[] = ["FR", "MC", "IT", "DK", "GB"]