import React, { useEffect, useState, ChangeEvent, FocusEvent } from "react";
import { useParams } from "react-router-dom";
import CardLoader from "../loader/CardLoader";
//import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { phonesNumberCountries } from "../config/constant";
import { /*handleSearchAdresses, handleChangeSearchedAdress,*/ handleChangeStringField, handleCheckBooleanFields} from "../functions/reactFieldChange";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { /*IFieldAdresse,*/ ISuppPalmares, ISuppPalmaresFields, ISuppPalmaresProps, ISuppPalmaresData } from "../type/type";
import { useQuery, useMutation } from "react-query";
import config from "../config/config";
import axios, { AxiosError } from "axios";
import { DateTime } from 'luxon';
import { Form } from "react-bootstrap";

const Palmares = () => 
{
    const logo = require("../assets/img/logo.png")
    const { id } = useParams()
    const [ loading, setLoading ] = useState<boolean>(true)
    const {data: palmares } = useQuery<ISuppPalmares>
    (
        "palmares"
        ,async () => 
        {
            return await axios.get(`${config.backUrl}`, 
            {
                params : 
                {
                    controller: "palmares",
                    id : id
                }
            }).then(response => 
            {
                setLoading(false)
                return response.data
            })
            .catch((error) => 
            {
                setLoading(false)
                if (error.response) 
                {
                    console.error(error.response.data.detail)
                } else if (error.request) 
                {
                    console.error(error.request)
                } else {
                    console.error(error.message)
                }
            })
        },
        {
            enabled : loading
        }
    )
    /*const [adresseSelectedPalmares, setAdresseSelected] = useState<IFieldAdresse>({
        label: '',
        name: '',
        postcode: '',
        city: ''
    })*/
    const [errorForm, setErrorForm] = useState({
        raisonSociale: {
            hadSelected: false,
            message: ""
        },
        nom: {
            hadSelected: false,
            message: ""
        },
        caN2: {
            hadSelected: false,
            message: ""
        },
        caN1: {
            hadSelected: false,
            message: ""
        },
        resultatN1: {
            hadSelected: false,
            message: ""
        },
        effectif: {
            hadSelected: false,
            message: ""
        },
        telephone: {
            hadSelected: false,
            message: ""
        },
        /*adresse: {
            hadSelected: false,
            message: ""
        },
        codePostal: {
            hadSelected: false,
            message: ""
        },
        ville: {
            hadSelected: false,
            message: ""
        },*/
        questionRseOne: {
            hadSelected: false,
            message: ""
        },
        questionRseTwo: {
            hadSelected: false,
            message: ""
        },
        questionRseThree: {
            hadSelected: false,
            message: ""
        }
    })
    const [fields, setFields] = useState<ISuppPalmaresFields>({
        raisonSociale: ""
        ,nom: ""
        ,caN2: 0
        ,caN1: 0
        ,resultatN1: 0
        ,effectif: 0
        ,telephone: ""
        ,fax: ""
        ,adresse: ""
        ,adresseComplement : ""
        ,codePostal: ""
        ,ville: ""
        ,questionRseOne: null
        ,questionRseTwo: null
        ,questionRseThree: null
    })
    useEffect(() => 
    {
        setErrorForm(
        {
            raisonSociale: {
                hadSelected: fields.raisonSociale !== "" ? true : false,
                message: ""
            },
            nom: {
                hadSelected: fields.nom !== "" ? true : false,
                message: ""
            },
            caN2: {
                hadSelected: fields.caN2 !== 0 ? true : false,
                message: ""
            },
            caN1: {
                hadSelected: fields.caN1 !== null ? true : false,
                message: ""
            },
            resultatN1: {
                hadSelected: fields.resultatN1 !== null ? true : false,
                message: ""
            },
            effectif: {
                hadSelected: fields.effectif !== null ? true : false,
                message: ""
            },
            telephone: {
                hadSelected: fields.telephone !== "" ? true : false,
                message: ""
            },
            /*adresse: {
                hadSelected: fields.adresse !== "" ? true : false,
                message: ""
            },
            codePostal: {
                hadSelected: fields.codePostal !== "" ? true : false,
                message: ""
            },
            ville: {
                hadSelected: fields.ville !== "" ? true : false,
                message: ""
            },*/
            questionRseOne: {
                hadSelected: fields.questionRseOne !== null ? true : false,
                message: ""
            },
            questionRseTwo: {
                hadSelected: fields.questionRseTwo !== null ? true : false,
                message: ""
            },
            questionRseThree: {
                hadSelected: fields.questionRseThree !== null ? true : false,
                message: ""
            }
        })
    }, [fields])
    const handleCheckError = ({ currentTarget }: ChangeEvent<HTMLInputElement> | FocusEvent<any>) => 
    {
        if (currentTarget.required && currentTarget.value === "")
        {
            setErrorForm({ ...errorForm, [currentTarget.name] : {
                message:`Ce champ est obligatoire`,
                hadSelected: true 
            }})
        }else {
            setErrorForm({ ...errorForm, [currentTarget.name]: {
                message: "",
                hadSelected: true 
            }})
        }
    }
    const mutationPalmares = useMutation(
        async ({fields, id} : ISuppPalmaresProps) => 
        {
            let data = {
                ...fields,
                id: id,
                controller: "palmares",
                action: "patch"
            } as ISuppPalmaresData;
            return axios.post(config.backUrl, data, {
                headers : {
                    "Content-Type" : "application/x-www-form-urlencoded"
                }
            })
        },
    )
    const handleSaveData = (id : number) => 
    {
        for (const [key, value] of Object.entries(errorForm)) 
        {
            if (value.message !== "" || !value.hadSelected) 
            {
                if(["questionRseOne", "questionRseTwo", "questionRseThree"].includes(key))
                {
                    toast.error(`Erreur : veuillez répondre à tous les questionnaires RSE`)
                }else
                {
                    toast.error(`Erreur : vérifier le champ ${key}`)
                }
                return
            }
        }

        const toastPalmares = toast.loading("Enregistrement en cours ...",
        {
            type: "success",
            position: "bottom-left",
            draggable: false,
            progress: 0,
            theme: "light"
        })
        mutationPalmares.mutateAsync({fields : fields, id: id } , 
        {
            onSuccess: () => 
            {
                toast.update(toastPalmares, 
                {
                    isLoading: false,
                    hideProgressBar: true,
                    render: "Enregistrement effectuée avec succès !"
                })
                setTimeout(() => { toast.dismiss(toastPalmares) }, 5000)
            },
            onError: (error) => 
            {
                const data = error as AxiosError
                toast.update(toastPalmares, 
                    {
                        isLoading: false,
                        hideProgressBar: true,
                        type: "error",
                        render: "Erreur lors de l'enregistrement, veuillez contacter l'administrateur SVP!"
                    })
                    setTimeout(() => { toast.dismiss(toastPalmares) }, 5000)
                console.error(data.message)
            }
        })
    }
    useEffect(() => 
    {
        setFields({
            raisonSociale: palmares?.raisonSociale ?? ""
            ,nom: palmares?.nom ?? ""
            ,caN2: palmares?.caN2 ?? 0
            ,caN1: palmares?.caN1 ?? 0
            ,resultatN1: palmares?.resultatN1 ?? 0
            ,effectif: palmares?.effectif ?? 0
            ,telephone: palmares?.telephone ?? ""
            ,fax: palmares?.fax ?? ""
            ,adresse: palmares?.adresse ?? ""
            ,adresseComplement: palmares?.adresseComplement ?? ""
            ,codePostal: palmares?.codePostal ?? ""
            ,ville: palmares?.ville ?? ""
            ,questionRseOne: palmares?.questionRseOne ?? null
            ,questionRseTwo: palmares?.questionRseTwo ?? null
            ,questionRseThree: palmares?.questionRseThree ?? null
        })
    }, [ palmares ])
    /*useEffect(() => 
    {    
        if (adresseSelectedPalmares.label) 
        {
            setFields({
                ...fields,
                adresse: adresseSelectedPalmares.name,
                codePostal: adresseSelectedPalmares.postcode,
                ville: adresseSelectedPalmares.city
            })
        }
    }, [adresseSelectedPalmares, fields])*/
    return (
        <div className='container-fluid'>
              <div className="row d-flex justify-content-center">
                  <div className="col-10">
                      <header className='d-flex justify-content-center my-4'>
                          <img src={logo} alt="Annuaire Tribune Bulletin Côte d'Azur" title="Annuaire Tribune Bulletin Côte d'Azur" />
                      </header>
                  </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className='col-8'>
                    {loading
                        &&
                            (<div className="card mt-4 shadow">
                                <div className="card-body">
                                    <CardLoader />
                                </div>
                            </div>)
                        ||
                        (
                            <>
                                <div className="card mt-4 shadow">
                                    <div className="card-header fw-bold fs-4">
                                        Mise à jour des données 
                                        {palmares?.id
                                            &&
                                            (
                                                <h4 className="card-subtitle fw-normal fs-5 my-1 text-muted">{palmares.raisonSociale} </h4>
                                            )
                                        }
                                    </div>
                                    <div className="card-body py-4">
                                        {(palmares !== undefined && palmares.id !== undefined)
                                            && 
                                            (
                                                <>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="raisonSociale" className="form-label">Raison sociale</label>
                                                            <input type="text" 
                                                                className="form-control" 
                                                                name="raisonSociale"
                                                                onBlur={handleCheckError}
                                                                value={fields.raisonSociale} 
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.raisonSociale.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.raisonSociale.message}</div>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="nom" className="form-label">Dirigeant</label>
                                                            <input type="text" 
                                                                className="form-control" 
                                                                name="nom"
                                                                onBlur={handleCheckError}
                                                                value={fields.nom} 
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.nom.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.nom.message}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="caN2" className="form-label">
                                                                CA 
                                                                <span className="badge bg-info ms-1">
                                                                    {(palmares.annualUpdate !== undefined && palmares.createdAt !== undefined
                                                                        && (DateTime.fromISO(palmares.createdAt) < DateTime.fromISO(palmares.annualUpdate)))
                                                                        &&
                                                                        (
                                                                            <>{DateTime.fromISO(palmares.annualUpdate).minus({ year : 2}).toFormat("yyyy")}</>
                                                                        )
                                                                        ||
                                                                        (
                                                                            <>{DateTime.now().minus({year: 2}).toFormat("yyyy")}</>
                                                                        )
                                                                    }
                                                                </span>
                                                            </label>
                                                            <input type="number" 
                                                                className="form-control" 
                                                                name="caN2"
                                                                onBlur={handleCheckError}
                                                                value={fields.caN2} 
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.caN2.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.caN2.message}</div>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="caN1" className="form-label">
                                                                CA
                                                                <span className="badge bg-info ms-1">
                                                                    {(palmares.annualUpdate !== undefined && palmares.createdAt !== undefined
                                                                        && (DateTime.fromISO(palmares.createdAt) < DateTime.fromISO(palmares.annualUpdate)))
                                                                        &&
                                                                        (
                                                                            <>{DateTime.fromISO(palmares.annualUpdate).minus({ year : 1}).toFormat("yyyy")}</>
                                                                        )
                                                                        ||
                                                                        (
                                                                            <>{DateTime.now().minus({year: 1}).toFormat("yyyy")}</>
                                                                        )
                                                                    }
                                                                </span>
                                                            </label>
                                                            <input type="number" 
                                                                className="form-control" 
                                                                name="caN1"
                                                                onBlur={handleCheckError}
                                                                value={fields.caN1} 
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.caN1.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.caN1.message}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="resultatN1" className="form-label">
                                                                Résultat 
                                                                <span className="badge bg-info ms-1">
                                                                    {(palmares.annualUpdate !== undefined && palmares.createdAt !== undefined
                                                                        && (DateTime.fromISO(palmares.createdAt) < DateTime.fromISO(palmares.annualUpdate)))
                                                                        &&
                                                                        (
                                                                            <>{DateTime.fromISO(palmares.annualUpdate).minus({ year : 1}).toFormat("yyyy")}</>
                                                                        )
                                                                        ||
                                                                        (
                                                                            <>{DateTime.now().minus({year: 1}).toFormat("yyyy")}</>
                                                                        )
                                                                    }
                                                                </span>
                                                            </label>
                                                            <input type="number" 
                                                                className="form-control" 
                                                                name="resultatN1"
                                                                onBlur={handleCheckError}
                                                                value={fields.resultatN1}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.resultatN1.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.resultatN1.message}</div>
                                                            }
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="effectif" className="form-label">
                                                                Effectif
                                                            </label>
                                                            <input type="number" 
                                                                className="form-control" 
                                                                name="effectif"
                                                                onBlur={handleCheckError}
                                                                value={fields.effectif} 
                                                                required={true}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                            {errorForm.effectif.message !== "" &&
                                                                <div className="text-danger errorMessage">{errorForm.effectif.message}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="tel" className="form-label">Téléphone</label>
                                                                    <PhoneInput
                                                                        name='telephone'
                                                                        value={fields.telephone}
                                                                        onBlur={handleCheckError}
                                                                        required={true}
                                                                        onChange={(value) =>{ 
                                                                            setFields({ ...fields, telephone: value ?? ""})
                                                                        }}
                                                                        displayInitialValueAsLocalNumber
                                                                        defaultCountry="FR"
                                                                        autoComplete={'new-' + fields.telephone}
                                                                        countries={phonesNumberCountries}
                                                                    />
                                                                    {errorForm.telephone.message !== "" &&
                                                                        <div className="text-danger errorMessage">{errorForm.telephone.message}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="fax" className="form-label">Fax</label>
                                                                    <PhoneInput
                                                                        name='fax'
                                                                        value={fields.fax}
                                                                        onChange={(value) =>{ 
                                                                            setFields({ ...fields, fax: value ?? ""})
                                                                        }}
                                                                        displayInitialValueAsLocalNumber
                                                                        defaultCountry="FR"
                                                                        autoComplete={'new-' + fields.fax}
                                                                        countries={phonesNumberCountries}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresse" className="form-label">Adresse</label>
                                                            <AsyncSelect
                                                                placeholder="Rechercher adresse..."
                                                                className="basic-single"
                                                                id='selectAdresse'
                                                                name="searchAdresse"
                                                                noOptionsMessage={() => "Aucune adresse correspondante"}
                                                                isClearable={true}
                                                                loadingMessage={() => "Recherche en cours..."}
                                                                loadOptions={handleSearchAdresses} 
                                                                onChange={(selectedOption) => handleChangeSearchedAdress(selectedOption, setAdresseSelected)}
                                                            />
                                                            <input 
                                                                type="text" 
                                                                className="form-control mt-1" 
                                                                value={fields.adresse} 
                                                                name="adresse" 
                                                                required={true}
                                                                onBlur={handleCheckError}
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                                {errorForm.adresse.message !== "" &&
                                                                    <div className="text-danger errorMessage">{errorForm.adresse.message}</div>
                                                                }
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="codePostal" className="form-label">Code postal</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.codePostal} 
                                                                        name="codePostal"
                                                                        onBlur={handleCheckError}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                    {errorForm.codePostal.message !== "" &&
                                                                        <div className="text-danger errorMessage">{errorForm.codePostal.message}</div>
                                                                    }
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="ville" className="form-label">Ville</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.ville}
                                                                        name="ville"
                                                                        onBlur={handleCheckError}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                    {errorForm.ville.message !== "" &&
                                                                        <div className="text-danger errorMessage">{errorForm.ville.message}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresseComplement" className="form-label">Complément d'adresse (optionnel)</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.adresseComplement} 
                                                                    name="adresseComplement"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                    </div>*/}
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <h6>
                                                                Transition écologique RSE
                                                            </h6>
                                                            <p className="text-muted">
                                                                En tant que dirigeant : 
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <label className="form-label">Avez-vous réalisé un bilan carbone de votre entreprise ?</label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Oui"
                                                                        id="questionRseOneYes"
                                                                        name="questionRseOne"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseOne: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseOne !== null && fields.questionRseOne}
                                                                    />
                                                                </div>
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Non"
                                                                        id="questionRseOneNo"
                                                                        name="questionRseOne"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseOne: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields, true)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseOne !== null && !fields.questionRseOne}
                                                                    />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <label className="form-label">Avez-vous embauché/formé un collaborateur pour la gestion de votre RSE ?</label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Oui"
                                                                        id="questionRseTwoYes"
                                                                        name="questionRseTwo"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseTwo: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseTwo !== null && fields.questionRseTwo}
                                                                    />
                                                                </div>
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Non"
                                                                        id="questionRseTwoNo"
                                                                        name="questionRseTwo"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseTwo: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields, true)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseTwo !== null && !fields.questionRseTwo}
                                                                    />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <label className="form-label">Avez-vous personnellement l'intention de suivre une formation de type RSE ?</label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Oui"
                                                                        id="questionRseThreeYes"
                                                                        name="questionRseThree"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseThree: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseThree !== null && fields.questionRseThree}
                                                                    />
                                                                </div>
                                                                <div className="col-1">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Non"
                                                                        id="questionRseThreeNo"
                                                                        name="questionRseThree"
                                                                        onChange={(e) => 
                                                                            {
                                                                                setErrorForm({
                                                                                    ...errorForm, questionRseThree: {
                                                                                        message: "",
                                                                                        hadSelected: true
                                                                                    }
                                                                                })
                                                                                handleCheckBooleanFields(e, fields, setFields, true)
                                                                            }
                                                                        }
                                                                        checked={fields.questionRseThree !== null && !fields.questionRseThree}
                                                                    />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3 d-flex justify-content-center">
                                                        <div className="col-5 d-flex justify-content-end">
                                                            <button type="button" className="text-uppercase btn btn-primary" onClick={() => handleSaveData(palmares.id) }>
                                                                Enregistrer (Même sans changement)
                                                            </button>
                                                        </div> 
                                                    </div>
                                                </>
                                            )
                                            ||
                                            (
                                                <div className="alert alert-danger" role="alert">
                                                    Impossible de charger les données, veuillez contacter l'administrateur du site
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                  <div className="col-10">
                      <footer className='d-flex justify-content-center my-4 text-muted'>
                        &copy; Tribune Côte d'Azur
                      </footer>
                  </div>
              </div>
          </div>
    )
}

export default Palmares;