import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardLoader from "../loader/CardLoader";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { specialites, langues, phonesNumberCountries } from "../config/constant";
import { handleSearchAdresses, handleChangeSearchedAdress, handleChangeStringField, handleCheckBooleanFields} from "../functions/reactFieldChange";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { IFieldAdresse, IClientAnnuaire, IClientAnnuaireFields, selectOption, updateAnnuaireProps, IClientAnnuaireData } from "../type/type";
import { useQuery, useMutation } from "react-query";
import config from "../config/config";
import axios, { AxiosError } from "axios";
import ReactDatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
//import { DateTime } from 'luxon';

const Annuaire = () => 
{
    const logo = require("../assets/img/logo.png")
    const { id } = useParams()
    const [ loading, setLoading ] = useState<boolean>(true)
    const {data: clientAnnuaire } = useQuery<IClientAnnuaire>
    (
        "clientAnnuaire"
        ,async () => 
        {
            return await axios.get(`${config.backUrl}`, 
            {
                params : 
                {
                    controller: "annuaire",
                    id : id
                }
            }).then(response => 
            {
                setLoading(false)
                return response.data
            })
            .catch((error) => 
            {
                setLoading(false)
                if (error.response) 
                {
                    console.error(error.response.data.detail)
                } else if (error.request) 
                {
                    console.error(error.request)
                } else {
                    console.error(error.message)
                }
            })
        },
        {
            enabled : loading
        }
    )
    const [adresseSelected, setAdresseSelected] = useState<IFieldAdresse>({
        label: '',
        name: '',
        postcode: '',
        city: ''
    })
    const [fields, setFields] = useState<IClientAnnuaireFields>({
        nom: ""
        ,prenom: ""
        ,tel: ""
        ,portable: ""
        ,fax: ""
        ,adresse: ""
        ,adresse2: ""
        ,eMail: ""
        ,codePostal: ""
        ,ville: ""
        ,casepalais: ""
        ,commissaireCompte: false
        ,infoComplementaire: []
        ,sermentDate: null
        ,specialite : ""
    })
    const [optionsInfoComplementaire, setOptionsInfoComplementaire] = useState<selectOption[]>([])
    
    const mutationAnnuaire = useMutation(
        async ({fields, id} : updateAnnuaireProps) => 
        {
            let data = {
                ...fields,
                id: id,
                controller: "annuaire",
                action: "patch",
                formType : "annuaire"
            } as IClientAnnuaireData;
            if(data.sermentDate != null)
            {
                data.dateSerment =  data.sermentDate.toLocaleDateString("fr")
                delete data.sermentDate
            }
            return axios.post(config.backUrl, data, {
                headers : {
                    "Content-Type" : "application/x-www-form-urlencoded"
                }
            })
        },
    )
    const handleSaveData = (id : number) => 
    {
        const toastAnnuaire = toast.loading("Enregistrement en cours ...",
        {
            type: "success",
            position: "bottom-left",
            draggable: false,
            progress: 0,
            theme: "light"
        })
        mutationAnnuaire.mutateAsync({fields : fields, id: id } , 
        {
            onSuccess: () => 
            {
                toast.update(toastAnnuaire, 
                {
                    isLoading: false,
                    hideProgressBar: true,
                    render: "Enregistrement effectuée avec succès !"
                })
                setTimeout(() => { toast.dismiss(toastAnnuaire) }, 5000)
            },
            onError: (error) => 
            {
                const data = error as AxiosError
                toast.update(toastAnnuaire, 
                    {
                        isLoading: false,
                        hideProgressBar: true,
                        type: "error",
                        render: "Erreur lors de l'enregistrement, veuillez contacter l'administrateur SVP!"
                    })
                    setTimeout(() => { toast.dismiss(toastAnnuaire) }, 5000)
                console.error(data.message)
            }
        })
    }
    useEffect(() => 
    {
        setFields({
            nom: clientAnnuaire?.idClient.nom ?? ""
            ,prenom: clientAnnuaire?.idClient.prenoms ?? ""
            ,tel: clientAnnuaire?.idClient.telFixe ?? ""
            ,fax: clientAnnuaire?.idClient.fax ?? ""
            ,adresse: clientAnnuaire?.idClient.adresse ?? ""
            ,adresse2: clientAnnuaire?.idClient.adresseComplement ?? ""
            ,eMail: clientAnnuaire?.idClient.email ?? ""
            ,codePostal: clientAnnuaire?.idClient.codePostal ?? ""
            ,ville: clientAnnuaire?.idClient.ville ?? ""
            ,infoComplementaire: clientAnnuaire?.idClient.infoComplementaire ?? []
            ,commissaireCompte: clientAnnuaire?.commissaireCompte ?? false
            ,portable: clientAnnuaire?.idClient.telMobile ?? ""
            ,casepalais: clientAnnuaire?.idClient.caseAvocat ?? ""
            ,sermentDate: (clientAnnuaire !== undefined && clientAnnuaire.idClient.dateSerment !== undefined &&  clientAnnuaire.idClient.dateSerment !== null) ? new Date(clientAnnuaire.idClient.dateSerment) : null
            ,specialite: clientAnnuaire?.idClient.specialite ?? ""
        })
        const infoComplementaire = (clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase() === "td") ? langues : specialites;
        setOptionsInfoComplementaire(infoComplementaire.map((item) => 
        { 
            return { label: item, value : item }
        }));
    }, [ clientAnnuaire ])
    useEffect(() => 
    {    
        if (adresseSelected.label) 
        {
            setFields({
                ...fields,
                adresse: adresseSelected.name,
                codePostal: adresseSelected.postcode,
                ville: adresseSelected.city
            })
        }
    }, [adresseSelected, fields])
    return (
        <div className='container-fluid'>
              <div className="row d-flex justify-content-center">
                  <div className="col-10">
                      <header className='d-flex justify-content-center my-4'>
                          <img src={logo} alt="Annuaire Tribune Bulletin Côte d'Azur" title="Annuaire Tribune Bulletin Côte d'Azur" />
                      </header>
                  </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className='col-8'>
                    {(loading)
                        &&
                            (<div className="card mt-4 shadow">
                                <div className="card-body">
                                    <CardLoader />
                                </div>
                            </div>)
                        ||
                        (
                            <>
                                <div className="card mt-4 shadow">
                                    <div className="card-header fw-bold fs-4">
                                        Mise à jour des données 
                                        {clientAnnuaire?.id
                                            &&
                                            (
                                                <h4 className="card-subtitle fw-normal fs-5 my-1 text-muted">Rubrique : {clientAnnuaire.idClient.clientActivite?.libelle} </h4>
                                            )
                                        }
                                    </div>
                                    <div className="card-body py-4">
                                        {(clientAnnuaire !== undefined && clientAnnuaire.id !== undefined)
                                            && 
                                            (
                                                <>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="nom" className="form-label">Nom</label>
                                                            <input type="text" 
                                                                className="form-control" 
                                                                name="nom"
                                                                value={fields.nom} 
                                                                onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="prenom" className="form-label">Prénom</label>
                                                            <input type="text" className="form-control" 
                                                                value={fields.prenom} 
                                                                name="prenom"
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="eMail" className="form-label">E-mail</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.eMail} 
                                                                    name="eMail"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="tel" className="form-label">Téléphone</label>
                                                                    <PhoneInput
                                                                        name='tel'
                                                                        value={fields.tel}
                                                                        onChange={(value) =>{ 
                                                                            setFields({ ...fields, tel: value ?? ""})
                                                                        }}
                                                                        displayInitialValueAsLocalNumber
                                                                        defaultCountry="FR"
                                                                        autoComplete={'new-' + fields.tel}
                                                                        countries={phonesNumberCountries}
                                                                    />
                                                                </div>
                                                                {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                                        && ["av", "ej", "td", "ec", "hu", "no"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                                    &&
                                                                    (
                                                                        <div className="col">
                                                                            <label htmlFor="portable" className="form-label">Portable</label>
                                                                            <PhoneInput
                                                                                name='portable'
                                                                                value={fields.portable}
                                                                                onChange={(value) => {
                                                                                    setFields({ ...fields, portable: value ?? "" })
                                                                                }}
                                                                                displayInitialValueAsLocalNumber
                                                                                defaultCountry="FR"
                                                                                autoComplete={'new-' + fields.portable}
                                                                                countries={phonesNumberCountries}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresse" className="form-label">Adresse</label>
                                                            <AsyncSelect
                                                                placeholder="Rechercher adresse..."
                                                                className="basic-single"
                                                                id='selectAdresse'
                                                                name="searchAdresse"
                                                                noOptionsMessage={() => "Aucune adresse correspondante"}
                                                                isClearable={true}
                                                                loadingMessage={() => "Recherche en cours..."}
                                                                loadOptions={handleSearchAdresses} 
                                                                onChange={(selectedOption) => handleChangeSearchedAdress(selectedOption, setAdresseSelected)}
                                                            />
                                                            <input type="text" className="form-control mt-1" value={fields.adresse} name="adresse" 
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="codePostal" className="form-label">Code postal</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.codePostal} 
                                                                        name="codePostal"
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="ville" className="form-label">Ville</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.ville}
                                                                        name="ville"
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresse2" className="form-label">Complément d'adresse (optionnel)</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.adresse2} 
                                                                    name="adresse2"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="fax" className="form-label">Fax</label>
                                                            <PhoneInput
                                                                name='fax'
                                                                value={fields.fax}
                                                                onChange={(value) => {
                                                                    setFields({ ...fields, fax: value ?? "" })
                                                                }}
                                                                displayInitialValueAsLocalNumber
                                                                defaultCountry="FR"
                                                                autoComplete={'new-' + fields.fax}
                                                                countries={phonesNumberCountries}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                        && ["ec", "se", "cc"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                    &&
                                                    (
                                                        <div className="row my-3">
                                                            <div className="col">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" 
                                                                        name="commissaireCompte"
                                                                        checked={fields.commissaireCompte}
                                                                        onChange={(e) => handleCheckBooleanFields(e, fields, setFields)} 
                                                                    />
                                                                    <label className="form-check-label" htmlFor="commissaireCompte">
                                                                        Commissaire aux comptes
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                        && ["ej", "td"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                        &&
                                                        (
                                                            <div className="row my-3">
                                                                <div className="col-6">
                                                                    <label htmlFor="infoComplementaire" className="col-form-label">
                                                                        { clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase() === "td" ? "Langues" : "Spécialités" }
                                                                    </label>
                                                                    <Select
                                                                        placeholder=""
                                                                        options={optionsInfoComplementaire}
                                                                        isMulti={true}
                                                                        defaultValue={fields.infoComplementaire?.map((item) => 
                                                                            { 
                                                                                return { label: item, value : item }
                                                                            })
                                                                        }
                                                                        value={fields.infoComplementaire?.map((item) => 
                                                                            { 
                                                                                return { label: item, value : item }
                                                                            })
                                                                        }
                                                                        onChange={(selectedOption) => 
                                                                        {
                                                                            setFields({...fields, "infoComplementaire": selectedOption.map(item => item.value) }) 
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                        && ["av"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                        &&
                                                        (
                                                            <>
                                                                <div className="row my-3">
                                                                    <div className="col">
                                                                        <label htmlFor="casepalais" className="form-label">Case Palais</label>
                                                                        <input type="text" className="form-control" 
                                                                                value={fields.casepalais} 
                                                                                name="casepalais"
                                                                                onChange={(e) => {
                                                                                    handleChangeStringField(e, fields, setFields)
                                                                            }} />
                                                                    </div>
                                                                    <div className="col">
                                                                        <label htmlFor="sermentDate" className="form-label">Date de serment</label>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <ReactDatePicker
                                                                                    id='sermentDate'
                                                                                    name='sermentDate'
                                                                                    locale={fr}
                                                                                    className="form-control"
                                                                                    selected={fields.sermentDate ? fields.sermentDate : null}
                                                                                    disabledKeyboardNavigation
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    onKeyDown={(e) => { e.preventDefault() }}
                                                                                    onChange={(date) => { setFields({...fields, "sermentDate": date }) }} 
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row my-3">
                                                                    <div className="col-6">
                                                                        <label htmlFor="specialite" className="col-form-label">Spécialités</label>
                                                                        <input type="text" className="form-control" 
                                                                            value={fields.specialite} 
                                                                            name="specialite"
                                                                            onChange={(e) => {
                                                                                handleChangeStringField(e, fields, setFields)
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className="row my-3 d-flex justify-content-end">
                                                        <div className="col-5 d-flex justify-content-end">
                                                            <button type="button" className="text-uppercase btn btn-primary" onClick={() => handleSaveData(clientAnnuaire.id) }>
                                                                Enregistrer (Même sans changement)
                                                            </button>
                                                        </div> 
                                                    </div>
                                                </>
                                            )
                                            ||
                                            (
                                                <div className="alert alert-danger" role="alert">
                                                    Impossible de charger les données, veuillez contacter l'administrateur du site
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                  <div className="col-10">
                      <footer className='d-flex justify-content-center my-4 text-muted'>
                        &copy; Tribune Côte d'Azur
                      </footer>
                  </div>
              </div>
          </div>
    )
}

export default Annuaire;