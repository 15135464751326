import axios from "axios"
import config from "../config/config"

export const handleChangeStringField = (e, fieldsState, setFieldsState, upperCase) => 
{
    setFieldsState({ ...fieldsState, [e.currentTarget.name]: upperCase ? (e.currentTarget.value).toUpperCase() : e.currentTarget.value })
}

export const handleChangeNumberField = (e, fieldsState, setFieldsState) => 
{
    setFieldsState({ ...fieldsState, [e.currentTarget.name]: parseInt(e.currentTarget.value) })
}
export const handleCheckBooleanFields = (e, fieldsState, setFieldsState, invert) => 
{
    setFieldsState({ ...fieldsState, [e.currentTarget.name]: invert ? !e.currentTarget.checked : e.currentTarget.checked })
}
export const handleSearchAdresses = async (adresseSearch) => 
{
    if(adresseSearch.trim().length > 2)
    {
        return await axios.get(`${config.backUrl}/`,
        {
            params: {
                controller: "apiGov",
                adresseSearch : adresseSearch.replace(' ', '+')
            }
        }).then((res) => 
        {
            let addresses = res.data
            let options = addresses.features?.map( (item) => ({ value: item.properties.label, label: item.properties.label, name: item.properties.name, postcode: item.properties.postcode, city: item.properties.city }));
            return options;
        })
    }
    return { options: [] }
}
export const handleChangeSearchedAdress = (selectedOption, setSelectedSearchItem) => 
{
    if (selectedOption) {
        setSelectedSearchItem(selectedOption)
    } else {
        setSelectedSearchItem({
            label: '',
            name: '',
            postcode: '',
            city: ''
        })
    }

}