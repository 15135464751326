import './front/assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import Annuaire from './front/page/Annuaire';
import TrombiNice from './front/page/TrombiNice';
import TrombiGrasse from './front/page/TrombiGrasse';
import Palmares from './front/page/Palmares';

const App = () =>
{
    return (
      <>
        <BrowserRouter>
          <Routes>
              <Route path="annuaire/:id" element={<Annuaire />} />
              <Route path="trombi/nice/:id" element={<TrombiNice />} />
              <Route path="trombi/grasse/:id" element={<TrombiGrasse />} />
              <Route path="trombi/grasse/photo/:id" element={<TrombiGrasse editPhoto={true} />} />
              <Route path="palmares/:id" element={<Palmares />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer
            position="bottom-left"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
      </>
    );
}

export default App;
